















































import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import Sidebar from "./sidebar.vue";

import Chart from "./chart";
import Navbar from "./navbar.vue";
import { constants } from "zlib";

@Component({
  name: "list",
  components: { Sidebar, Navbar },
})
export default class List extends Vue {
  @Prop() id: number | undefined;

  charts: Chart[] = [];
  placeholderURL = "https://bulma.io/images/placeholders/1280x960.png"

  created() {
    this.loadData();
  }

  @Watch("$route")
  loadData() {
    const p = this.$route.params;
    const cStr = "id" in p ? `?category=${p.id}` : "";
    const urlCharts = `/api/charts/${cStr}`;
    axios.get(urlCharts).then((ch) => {
      this.charts = ch.data.data.sort((a, b) => {
        const af = !!a.featured, bf = !!b.featured;
        if (af === bf) {
          return a.id - b.id;
        } else {
          return bf ? 1 : -1;
        }
      });
    });
  }
}
