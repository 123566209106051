

























import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  name: "sidebar",
})
export default class SideBar extends Vue {
  categories: { name: string, id: number}[] = [];
  @Prop() id: number | undefined;

  created() {
    this.loadData();
  }

  activeClass(id) {
    return { "is-active": this.id == id }
  }

  @Watch("$route")
  loadData() {
    const urlCategories = `/api/categories/`;
    axios.get(urlCategories).then((ca) => {
      this.categories = ca.data.data;
    });
  }
}
