























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

function toData(root: any) {
    // if (root._skipped) return [root.children.map(toData)];
    const obj: any = {
        text: root._name,
        data: root,
    }
    if (root.children && root.children.length) {
        const c = root.children[0]._skipped ? root.children[0] : root;
        obj.children = c.children.filter(x => x.isActive).map(toData);
    }
    return obj;
}

@Component({
  name: "inspector",
})
export default class Inspector extends Vue {
    @Prop() visualizer: any;
    @Prop() showInfoPanel;

    isMounted = false;
    currentData: any = null;

    iTreeOptions = {
      nodeIndent: 16,
    };

    dTreeOptions = {
      nodeIndent: 12,
    };

    updated() {
      this.isMounted = true;
    }

    @Watch("visualizer")
    visualizerUpdated() {
      if (!this.isMounted) return;
      (this.$refs.tree as any).setModel(this.treeData);
    }

    @Watch("currentData")
    currentDataUpdated() {
      if (!this.showInfoPanel) return;
      this.$nextTick(() => {
        (this.$refs.propTree as any).setModel(this.propTreeData);
        (this.$refs.geoTree as any).setModel(this.geoTreeData);
      });
    }

    nodeSelected(e) {
      this.currentData = e.data;
    }

    updateInfo(show: boolean) {
      this.$emit("update:showInfoPanel", show);
    }

    get propTreeData() {
      if (!this.currentData) return [];
      return this.objectData(null, this.currentData.prop).children;
    }

    get geoTreeData() {
      if (!this.currentData) return [];
      return this.objectData(null, this.currentData.g).children;
    }

    objectData(key: string | null, data: any) {
      const type = typeof data;
      const obj: any = { data: { type, keyName: key }};
      if (key === null) {
        obj.state = { expanded: true };
      }
      switch (type) {
        case "object":
          if (Array.isArray(data)) {
            obj.data.type = "array"
            obj.text = "Array"
            obj.children = data.map((v, i) => this.objectData(i.toString(), v));
          } else if (data === null) {
            obj.data.type = "null";
            obj.text = "null";
          } else {
            obj.text = "Object";
            obj.children = Object.keys(data).map(k => this.objectData(k, data[k]));
          }
          break;
        case "string":
          obj.text = `"${data}"`;
          break;
        case "number":
        case "boolean":
          obj.text = data.toString();
          break;
      }
      return obj;
    }

    get treeData() {
        return [toData(this.visualizer)];
    }

    childrenInfo(node) {
        return `{${node.children.length}}`;
    }

    t(n: number) {
        return n.toFixed(2).replace(/0+$/, "").replace(/\.$/, "");
    }
}
