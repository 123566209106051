






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "app",
})
export default class App extends Vue {
  public message = "########"
}
