/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag "hello_vue" %> (and
// <%= stylesheet_pack_tag "hello_vue" %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Buefy from "buefy";
import VueCodemirror from "vue-codemirror"
import LiquorTree from 'liquor-tree'

import App from "../page/app.vue";
import Chart from "../page/chart.vue";
import List from "../page/list.vue";

import "codemirror/mode/javascript/javascript.js";
import "codemirror/keymap/vim.js";
import "codemirror/lib/codemirror.css";
import "../one-dark.css";
import "@mdi/font/css/materialdesignicons.min.css";
import "../bulma-ext.sass";

import * as CodeMirror from "codemirror";
import bvtMode from "bvt-mode";
CodeMirror.defineMode('bvt', bvtMode);

Vue.use(VueRouter);
Vue.use(Buefy);
Vue.use(VueCodemirror);
Vue.use(LiquorTree)

const routes: RouteConfig[] = [
  { path: "/", component: List },
  { path: "/categories/:id", component: List, props: true },
  { path: "/charts/:id", component: Chart, props: true }
];

const router = new VueRouter({
  routes,
});

document.addEventListener("DOMContentLoaded", () => {
  const app = new Vue({
    render: h => h(App),
    router,
  }).$mount('#app');
});
