


























































































































































































































import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";

import Chart from "./chart";
import Inspector from "./inspector.vue";
import Navbar from "./navbar.vue";
import * as CodeMirror from "codemirror";
import { setTimeout, clearTimeout } from "timers";

function getLocalOpt(name: string, defaultValue: string): string {
  const item = localStorage.getItem(name);
  return item === null ? defaultValue : item;
}

@Component({
  name: "chart",
  components: { Navbar, Inspector },
})
export default class chart extends Vue {
  chart: Chart = { template: "", script: "" } as any;
  chartData: any = null;
  userChartData: string = null;
  otherChartData: Record<string, string> = {};
  currChartData: string = null;

  currentEditor = "template";
  get cmOptions() {
    const useTab = this.currentEditor === "template" && this.useTab;
    const extraKeys = useTab ? {} : {
      Tab: (cm) => cm.execCommand("indentMore"),
      "Shift-Tab": (cm) => cm.execCommand("indentLess"),
    };
    return {
      lineNumbers: true,
      theme: "one-dark",
      mode: this.currentEditor === "template" ? "bvt" : "javascript",
      keyMap: this.useVim ? "vim" : "default",
      indentUnit: 4,
      indentWithTabs: useTab,
      extraKeys,
    };
  };

  background = "";
  bgColors = [
    { key: "#101010", value: "Black" },
    { key: "#363636", value: "Dark" },
    { key: "#f4f4f4", value: "Light" },
    { key: "white", value: "White" },
  ]

  fontSize = 12;
  useVim = false;
  useTab = true;
  autoRefresh = false;

  hasError = false;
  errorMessage = "";

  private firstLoad = false;
  private iframeLoaded = false;
  private showDataPanel = false;
  private showInspector = false;
  private showInspectorInfoPanel = true;
  private visualizer = null;
  private uploadModalShowing = false;
  private isUploading = false;
  private isUploaingDefaultData = false;
  private currUploadingData = { name: "", file: null as File };

  private lastTmChanged = null;
  private timeout = null;
  private timeoutRunning = false;
  private totalTmChangeCount = 0;

  created() {
    this.loadData();
    this.fontSize = parseInt(getLocalOpt("cruxDemo_fontSize", "12"));
    this.background = getLocalOpt("cruxDemo_bg", "#f4f4f4");
    this.useVim = getLocalOpt("cruxDemo_vim", "0") === "1";
    this.useTab = getLocalOpt("cruxDemo_tab", "1") === "1";
    this.autoRefresh = getLocalOpt("cruxDemo_autoRefresh", "0") === "1";
  }

  mounted() {
    (this.$refs.cm as any).codemirror.setSize("100%", "100%");
    this.updateFontSize();
    this.iframe.onload = () => {
      this.iframeLoaded = true;
      this.checkReady();
    }
    window.addEventListener("message", (e) => {
      if (e.origin !== "https://s.chart.oviz.org" &&
          e.origin !== "http://s.visualization.lvh.me:3000") return;
      const { name, message } = e.data;
      if (name === "error") {
        this.hasError = true;
        this.errorMessage = message;
        this.visualizer = null;
      } else if (name === "visualizer") {
        this.visualizer = e.data.visualizer;
      }
    }, false);
  }

  checkReady() {
    if (this.iframeLoaded && this.chart.name && (!this.chart.data || this.chartData)) {
      this.setBackground();
      this.runCode();
      this.firstLoad = true;
    }
  }

  @Watch("$route")
  loadData() {
    const urlChart = `/api/charts/${this.$route.params.id}`;
    axios.get(urlChart).then((ch) => {
      this.chart = ch.data.data;
      if (this.chart.data) {
        axios.get(this.chart.data).then((d) => {
          this.chartData = d.data;
          this.checkReady();
        }).catch(e => {
          this.hasError = true;
          this.errorMessage = `Error loading demo data. Please check your Internet connection and retry.\n${e.toString()}`;
        });
      }
      this.checkReady();
    }).catch(e => {
      this.hasError = true;
      this.errorMessage = `Error loading chart. Please check your Internet connection and retry.\n${e.toString()}`;
    });
  }

  @Watch("background")
  setBackground() {
    this.iframe.contentWindow.postMessage({
      name: "bg",
      data: {
        color: this.background,
      },
    }, this.iframeDomain);
    localStorage.setItem("cruxDemo_bg", this.background);
  }

  @Watch("useVim")
  setVimMode() {
    localStorage.setItem("cruxDemo_vim", this.useVim ? "1" : "0");
  }

  @Watch("autoRefresh")
  setAutoRefresh() {
    localStorage.setItem("cruxDemo_autoRefresh", this.autoRefresh ? "1" : "0");
  }

  @Watch("useTab")
  convertFileIndention() {
    if (this.useTab) {
      this.chart.template = this.chart.template.replace(/    /g, "\t")
    } else {
      this.chart.template = this.chart.template.replace(/\t/g, "    ")
    }
    localStorage.setItem("cruxDemo_tab", this.useTab ? "1" : "0");
  }

  @Watch("chart.script")
  @Watch("chart.template")
  @Watch("userChartData")
  @Watch("otherChartData")
  tmChanged() {
    if (!this.autoRefresh || !this.firstLoad) return;
    if (this.timeoutRunning) {
      clearTimeout(this.timeout);
    }
    if (this.totalTmChangeCount > 10) {
      this.totalTmChangeCount = 0;
      this.runCode();
    } else {
      this.timeout = setTimeout(() => {
        this.timeoutRunning = false;
        this.totalTmChangeCount = 0;
        this.runCode();
      }, 500);
      this.timeoutRunning = true;
    }
    this.totalTmChangeCount += 1;
  }

  runCode() {
    this.hasError = false;
    this.errorMessage = "";
    const data = {
      data: this.userChartData || this.chartData,
      ...this.otherChartData,
    };
    this.iframe.contentWindow.postMessage({
      name: "update",
      data: {
        template: this.chart.template,
        script: this.chart.script,
        data,
      },
    }, this.iframeDomain);
  }

  addFontSize(size: number) {
    this.fontSize += size;
    if (this.fontSize < 10) this.fontSize = 10;
    if (this.fontSize > 24) this.fontSize = 24;
    this.updateFontSize();
  }

  updateFontSize() {
    const cm = (this.$refs.cm as any).codemirror;
    cm.getWrapperElement().style["font-size"] = `${this.fontSize}px`;
    cm.refresh();
    localStorage.setItem("cruxDemo_fontSize", this.fontSize.toString());
  }

  previewData(name: string | number) {
    let str: string;
    if (name === 0) {
      str = this.chartData;
    } else if (name === 1) {
      str = this.userChartData;
    } else {
      str = this.otherChartData[name];
    }
    if (str) {
      this.currChartData = str.substr(0, 10000);
    }
  }

  uploadData(isDefault = false) {
    this.isUploaingDefaultData = isDefault;
    this.currUploadingData = { name: `data${Object.keys(this.otherChartData).length + 2}`, file: null };
    this.uploadModalShowing = true;
  }

  confirmUpload() {
    this.isUploading = true;
    const { name, file } = this.currUploadingData;
    let errorMessage: string;
    let fileContent: string;
    if (!this.isUploaingDefaultData) {
      if (!name.match(/^[A-z_][A-z0-9_]*$/)) {
        errorMessage = "Name must be a valid Javascript variable name.";
      }
      if (name === "data" || name in this.otherChartData) {
        errorMessage = `Data with name "${name}" already exists.`;
      }
    }
    if (!file) {
      errorMessage = "File must present."
    }
    if (errorMessage) {
      this.showError(errorMessage);
      this.isUploading = false;
      return;
    }
    const reader = new FileReader();
    reader.onload = (e: any) => {
      if (this.isUploaingDefaultData) {
        this.userChartData = e.target.result;
      } else {
        this.otherChartData[name] = e.target.result;
      }
      this.isUploading = false;
      this.uploadModalShowing = false;
    }
    reader.onerror = (e: any) => {
      this.showError(e.target.error);
      this.isUploading = false;
    }
    reader.readAsText(file);
  }

  showError(errorMessage: string) {
    this.$snackbar.open({
      message: errorMessage,
      type: "is-danger",
      position: "is-top",
    });
  }

  get iframe() {
    return this.$refs.iframe as HTMLIFrameElement;
  }

  get iframeDomain() {
    const { protocol, host } = window.location;
    return `${protocol}//s.${host}`;
  }

  get iframeLocation() {
    return `//s.${window.location.host}`;
  }
}
